import React, { Component } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ParticlesBg from 'particles-bg'
import SocialFollowBottom from './SocialFollowBottom';
import "./Education.css"
import "./custom.css"




export class Education extends Component {
  static displayName = Education.name;

 

  render () {
    
        
    
    return (
      <div className ="page" >
            <div className="infoBox">
            <div class="separator">
                <div class="line1"></div>
                    <h1 className="headlineE">Education</h1>
                <div class="line2"></div>
            </div>

                <VerticalTimeline>
                <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}
                        date="2021 - present"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">BSc Business Management </h3>
                        <h4 className="vertical-timeline-element-subtitle">King’s College London, London</h4>
                        <p>
                        I am currently undertaking the last year of my bachelor’s in business management at King’s College London where I am well on my way to achieve an expected first-class honour. 
                        This degree provides great levels of autonomy in relation to courses, and as such during my 3 years I have chosen to engage in a mix of data analytical classes, marketing courses and finance related subjects. 
                        I chose this in order to allow myself a well-rounded understanding of multiple departments within the business sector. Moreover, it has allowed me to select courses for my final year where these fields overlap, hence providing classes that more greatly reflect actual job situations, such as Marketing Analytics. 
                        <br></br>  
                        Additionally, I have been accepted onto King’s Consultancy Project, where I get to combine my knowledge from the past years, by working with a group of peers, where we must act as consultants for a pre-selected company, by addressing their needs to the best of or abilities. 
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2020 - 2020"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Advanced Mathematics </h3>
                        <h4 className="vertical-timeline-element-subtitle">Tiegten Business School, Odense</h4>
                        <p>
                        During the summer of 2020 just after graduating from high school, I spend 8 weeks studying advanced mathematics, thus reaching the hight possible level of mathematics obtainable prior to university. I graduated with a 12 (highest possible grade in the Danish educational system) in both my written and oral exam.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2017 - 2020"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">High School </h3>
                        <h4 className="vertical-timeline-element-subtitle">Tiegten Business College, Odense</h4>
                        <p>
                        I attended a business high school where I majored in, English, Micro & Macro Economy, Spanish and Marketing, with minors including Finance, History and Social Studies.                         </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2016 - 2017"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Chile</h3>
                        <h4 className="vertical-timeline-element-subtitle">Exchange, Vallenar</h4>
                        <p>
                        Between primary and high school, I went abroad to Chile as an exchange student, hence I lived with a host family and attended school. The school year itself was uncredited, as it was outside of my education in Denmark, with the primary goal being personal development, cultural understanding and learning to speak and write Spanish fluently. 
                        </p>
                    </VerticalTimelineElement>                    
                </VerticalTimeline>
              
             </div>  
             <div className ="bottembar" >
                <SocialFollowBottom />
            </div>
      </div>
   
    );
  }
}
  
