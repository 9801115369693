import React, { Component } from 'react'
import SocialFollowBottom from './SocialFollowBottom';
import "./Skills.css"
import "./custom.css"




export class Skills extends Component {
  static displayName = Skills.name;

  render () {
    return (
     
      <div className ="page" >
          <div className="infoBox">
          <div class="separator">
          <div class="line1"></div>
              <h1 className="headline">Skills</h1>
          <div class="line2"></div>
          </div>
          <p className="headLines"> Professional skills</p>
          <div className="allSkillBoxes">
          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/office-365.png" alt="office-365"/>           
          <p className="skill">Microsoft 365
            </p>
          </div>
          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/ms-excel.png" alt="ms-excel"/>          
          <p className="skill">Excel Coding 
            </p>
          </div>

          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/adobe-logo.png" alt="adobe-logo"/>         
           <p className="skill">Adobe: Instructor and Photohop
            </p>
          </div>
          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/salesforce.png" alt="salesforce"/>          
          <p className="skill">Salesforce 
            </p>
          </div>

          
        </div>
        <p className="headLines"> Personal skills</p>

        <div className="allSkillBoxes">
          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/windows10-personalization.png" alt="windows10-personalization"/>          
          <p className="skill">Creative and analytical thinking abilities
            </p>
          </div>

          <div className="skillbox">
          <img width="64" height="64" src="https://img.icons8.com/external-others-cattaleeya-thongsriphong/64/eeeae1/external-Organize-calendar-and-date-others-cattaleeya-thongsriphong-6.png" alt="external-Organize-calendar-and-date-others-cattaleeya-thongsriphong-6"/>           
          <p className="skill">Advanced organisational and structural skills
            </p>
          </div>
          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/goal--v1.png" alt="goal--v1"/>          
          <p className="skill">Ambitious and determined
            </p>
          </div>

          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios/50/eeeae1/people-working-together.png" alt="people-working-together"/>          
           <p className="skill"> Communication and teamwork
            </p>
          </div>
          <div className="skillbox">
          <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/calculator.png" alt="calculator"/>         
           <p className="skill"> Mathematical and numberminded
            </p>
          </div>
        </div>

        <p className="headLines"> Languages</p>

        <div className="allSkillBoxes">
          <div className="skillbox">
          <img src="https://img.icons8.com/color/48/null/denmark-circular.png"/>       
          <p className="skill">Danish
            </p>
            <p className="skillU"><i>Fluent</i>
            </p>
          </div>

          <div className="skillbox">
          <img src="https://img.icons8.com/color/48/null/great-britain-circular.png"/>          
          <p className="skill">English
            </p>
            <p className="skillU"><i>Fluent</i>
            </p>
          </div>
          <div className="skillbox">
          <img width="48" height="48" src="https://img.icons8.com/color/48/spain-circular.png" alt="spain-circular"/>          <p className="skill"> Spanish 
            </p>
            <p className="skillU"><i>Fluent</i>
            </p>
          </div>

          <div className="skillbox">
          <img src="https://img.icons8.com/color/48/null/germany-circular.png"/>         
          <p className="skill"> German
            </p>
            <p className="skillU"><i>Understanding</i>
            </p>
          </div>
        </div>
    </div>  


      <div className ="bottembar" >
        <SocialFollowBottom />
      </div>
      <a className="linkIcon" target="_blank" href="https://icons8.com/">Icons provided by icon8</a>
    </div>
   
    );
  }

  
}