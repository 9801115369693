import React, { Component } from 'react';
import Profile from "./Images/profile.png"
import ParticlesBg from 'particles-bg'
import SocialFollow from './SocialFollow';
import './Home.css'
import './custom.css'


export class Home extends Component {
  static displayName = Home.name;



  render () {
    return (
      <div className ="context" >
        <ParticlesBg className="background" color={"#D0B8B4"} type="circle" num={4} width='100vw' height="100vh" bg={true} config={{fps:2000}}/>
         
      <div className ="content">
        <div className="innerContent">
        <img src={Profile} className="billede "alt="pp"/>
        <h1>Natacha Drest Rasmussen</h1>
        <h2> BSc. Business Management</h2>
        <SocialFollow />
        </div>
      </div> 
      </div>
   
    );
  }
}
