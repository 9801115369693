import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Education } from './components/Education';
import { AboutMe } from './components/AboutMe';
import { Work } from './components/Work';
import { Skills } from './components/Skills';
import './components/custom.css';

export default function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/education" element={<Education />} />
        <Route path="/work" element={<Work />} />
        <Route path="/skills" element={<Skills />} />
      </Routes>
    </Layout>
  );
}
