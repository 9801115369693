import React, { Component } from 'react'
import SocialFollowBottom from './SocialFollowBottom';
import "./AboutMe.css"
import "./custom.css"
import AboutMePhoto from "./Images/AboutMe.png"
import Waterpolo from "./Images/WaterPolo.jpg"
import Career from "./Images/Career.png"
import Travel from "./Images/travel.png"


export class AboutMe extends Component {
  static displayName = AboutMe.name;

  render () {
    return (
     
      <div className ="page" >
          <div className="infoBox">
          <div class="separator">
          <div class="line1"></div>
              <h1 className="headline">About me</h1>
          <div class="line2"></div>
          </div>
          
              <div className="contentDiv">
                <img src={AboutMePhoto} className="horizontalPhoto "alt="pp"/>
                  <p className="text">
                    <p className="underHeadline">General Info</p>
                    Hi, my name is Natacha, I am a Danish woman currently studying at King’s College London, where I am in the process of doing a bachelor’s in business management, which I will graduate from in the summer of 2024. I have built this online CV, in order to give you a more nuanced understanding of who I am, my background and future aspirations. I am excited for you to get to know more about me and looking forward to hopefully hearing from you. 
                      <ul>
                        <li>Email: natacha.drest@gmail.com</li>
                        <li>Phone: +45 27 47 37 23 </li>
                      </ul>
                    </p>
              </div> 

              <div className="contentDiv">
                <p className="text">
                  <p className="underHeadline">Career Aspirations</p>
                  I am currently living in London, as I am studying at King’s College London, however, I was born and raised in Denmark. During my years in Danish school, I was very numerical and excelled in classes that took the approach of logical reasoning, however, as I have gotten older, my interests outside school became far more creative.  As such, I aspire to combine the ability of make decisions through logical reasoning and a data analytical mindset, with a more creative and out-side the box way of problem solving. Thus, I chose to do my bachelor’s degree in business management, as it facilitates autonomy in relation to courses, hence allowing me to pursue a constellation of logical reasoning classes such as quantitative data analytics and finance combined with more creative courses within the field of marketing. Ultimately preparing me for a similar constellation in my later career, where I can deploy my strengths rather than my weaknesses.   </p>
                    <img src={Career} className="horizontalPhoto"alt="pp"/>
              </div> 

              <div className="contentDiv">
                <img src={Travel} className="horizontalPhoto"alt="pp"/>
                  <p className="text">
                    <p className="underHeadline">Travels </p>
                    During my upbringing I travelled a lot with my family and got to see many different places. These experiences made me curious and excited about exploring  new cultures on a deeper level, as I felt there was so much to learn both in relation to broadening my opinions and becoming more open minded. When I was 15 years old, I chose to go abroad for a year, to become an exchange student in Chile. Through this journey I went through a process of personal development, where I got to grow mentally, by facing unusual and difficult situations, such as being in a Spanish speaking country with no prior knowledge of the language. After returning to Denmark and finishing high school, I was meant to do a gap year full of travels, consisting of 6 months living and working in Barcelona, followed by 3 months travelling in Asia. However due to Covid-19 this became impossible. Loosing this opportunity, only reassured me that I was ready to experience much greater levels of diversity and multiculturality, which made my decision of moving to London one of the easiest so far. 
                     </p>
              </div> 

              <div className="contentDiv">
                  <p className="text">
                    <p className="underHeadline">Water Polo</p>
                    For the majority of my life, I have practised water sports, first as a junior elite swimmer, next as a junior national team participant sailor, and lastly as a water polo player. To this day I have practised water polo for more than 10 consecutive years, throughout which I have played with multiple different teams, including both the Danish National Junior and Women’s Senior Team for multiple years. Thus, I have spent many hours in the pool practising, whilst having to attend school and a part time job, naturally making me very competitive, yet equally organised, structured and disciplined, both inside and outside the pool. Since moving to London, I have been a part of the King’s College London Women’s Water Polo Team, which I captained during my second year, leading us to a league victory, and a first-time promotion into the UK premier division. 
                     </p>
                     <img src={Waterpolo} className="horizontalPhoto "alt="pp"/>
              </div> 
          </div>  

      <div className ="bottembar" >
        <SocialFollowBottom />
      </div>
    </div>
   
    );
  }

  
}
