import React, { Component } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ParticlesBg from 'particles-bg'
import SocialFollowBottom from './SocialFollowBottom';
import "./Work.css"
import "./custom.css"





export class Work extends Component {
  static displayName = Work.name;

 

  render () {
    
        
    
    return (
      <div className ="page" >
             <div className="infoBox">
             <div class="separator">
                <div class="line1"></div>
                    <h1 className="headline">Work</h1>
                <div class="line2"></div>
            </div>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2022 - Present"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Student Marketeer – Red Bull</h3>
                        <h4 className="vertical-timeline-element-subtitle">Red Bull, London</h4>
                        <p>
                            <ul>
                                <li>Driving the brand image and product understanding in my university and country.</li>
                                <li>Organise local/university based Red Bull events, alongside contribute to larger national/international events.</li>
                                <li>Structure work schedules, in field workflow, logistics, and post-work reporting. </li>
                            </ul>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2023 - 2023"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Summer Intern “Business Analyst” – Brandwatch</h3>
                        <h4 className="vertical-timeline-element-subtitle">Brandwatch, Copenhagen</h4>
                        <p>
                        <ul>
                                <li>Conducted detailed market reports, through TAM calculations, to identify unexplored opportunities.</li>
                                <li>Gathered, analysed, and presented data for implementation in relation to internal enablement optimisation.</li>
                                <li>Closely collaborated with managers and head of departments, to identify pinpoints and areas for improvement. </li>
                            </ul>
                            </p>                  
                             </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2022 - 2023"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Executive Student engagement associate – King’s College London</h3>
                        <h4 className="vertical-timeline-element-subtitle">King’s College London, London</h4>
                        <p>
                        <ul>
                                <li>Overlooked 5 other associates who each worked with multiple first year students, hence reporting their work, communicating information, and assisted on any needs that they had in relation to their position.</li>
                                <li>Worked with my own 5 first year students, by guiding them towards the best possible experience at King’s College London, whilst also serving as one of their main point of contacts on campus.  </li>
                            </ul>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2022 - 2023"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Women’s Water Polo Captain – King’s College London</h3>
                        <h4 className="vertical-timeline-element-subtitle">King’s College London, London</h4>
                        <p>
                        <ul>
                                <li>Planned, scheduled, and executed all trainings alongside administrating all communication channels,</li>
                                <li>Organised every match, in relation to transportation, logistics, and cross university communication. </li>
                                <li>Lead my team to win their league and their final playoffs, allowing us to move into the premier division.  </li>
                            </ul>
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2022 - 2022"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Summer Intern “Marketing Associate” – Signs & Marks</h3>
                        <h4 className="vertical-timeline-element-subtitle">Signs & Marks, Odense</h4>
                        <p>
                        <ul>
                                <li>Co-organised a larger promotion initiative with the purpose of targeting potential customers</li>
                                <li>Brainstormed and collaborated with the design department to create high-quality social media strategies.</li>
                                <li>Managed all tasks in relation to the back end of the company Web shop. </li>
                            </ul>                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF9EB', color: '#00000' }}

                        date="2020 - 2021"
                        iconStyle={{ background: '#E3CDC9', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">Personal Assistant to the Managing Director  - Signs & Marks</h3>
                        <h4 className="vertical-timeline-element-subtitle">Signs & Marks, Odense</h4>
                        <p>
                        <ul>
                                <li>Created analytical reports concerning client purchasing patterns and past needs, to create internal strategies</li>
                                <li>Organised and participated in client meetings, whilst trusted with responsibilities such as conducting and distributing meeting summaries and administrating the agreed upon follow-ups.</li>
                        </ul>                        
                        </p>
                    </VerticalTimelineElement>
                    
                    
                    
                    
                </VerticalTimeline>
             </div>  
             <div className ="bottembar" >
                <SocialFollowBottom />
            </div>
      </div>
   
    );
  }
  }
  
