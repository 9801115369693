import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedin,
}from "@fortawesome/free-brands-svg-icons";
import resume from './Resume-NatachaDrest.pdf';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faEnvelope, faFile} from '@fortawesome/free-solid-svg-icons';
import './SocialFollowBottom.css';
import "./custom.css"


library.add(faEnvelope);

export default function SocialFollow() {
    return (
        <div class="social-container">
        <a target="_blank" href="https://www.linkedin.com/in/natacha-drest-81626b217/"
            className="linkedinSocial">
                <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <Mailto email="natacha.drest@gmail.com">
        <FontAwesomeIcon className="mailSocialBottom" icon={faEnvelope} />
        </Mailto>
        <a href={resume} download="Resume-NatachaDrest">
        <img className="resumeBottom" width="50" height="50" src="https://img.icons8.com/ios-filled/50/eeeae1/open-resume.png" alt="open-resume"/>        </a>  
        </div>
    );
}

function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }